@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --dark: rgb(22, 22, 22);
    --white: rgb(255, 255, 255);
    --gray-400: rgb(203, 203, 203);
    --color-text-base: 6, 6, 17;
    --color-text-label: 102, 111, 117;
    --color-text-placeholder: 160, 166, 172;
    --color-text-warning: 225, 55, 86;
    --color-text-inverted: 255, 255, 255;
    --color-bg-base: 249, 249, 251;
    --color-bg-card: 255, 255, 255;
    --color-button-primary: 255, 255, 255;
    --color-button-inverted: 6, 6, 17;
    --color-accent-primary: 0, 202, 185;

    --normal-border: hsl(0, 0%, 95.1%);
    --normal-text: hsl(0, 0%, 9%);
    --success-bg: hsl(143, 85%, 96%);
    --success-border: hsl(145, 92%, 91%);
    --success-text: hsl(140, 100%, 27%);
    --error-bg: hsl(359, 100%, 97%);
    --error-border: hsl(359, 100%, 94%);
    --error-text: hsl(360, 100%, 45%);
  }
  /* Define a new theme with different colors */
}

.react-datepicker {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.react-datepicker__close-icon::after, .react-datepicker__day--selected {
  background-color: #060611 !important;
}